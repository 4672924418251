// ---> css for grid header
.table-header-with-plus {
	width: 100%;
	.table-header-label {
			font-size: 1.4rem
	}
	.table-plus-btn {
			float:right;
	}
	.table-plus-btngroup {
		float: right;
		.table-plus-btngroup-btn1 {
			margin-right: 10px;
		}
	}
}
// <---

// --> css for pagination
.grid-pagination {
	.page-input {
			width: 70px;
			margin: 0 0.25rem 0 0.25rem;
	}
	.page-input-label {
			margin:0 0 0 0.25rem;
			line-height: 2rem;
	}
	ul {
			margin-bottom: 0;
	}
}
// <--

// --> css for grid filter
.grid-filter {
	.filter-input {
			width: 300px;
			float: left;
	}
	.filter-button {
			margin: 0 0 0 0.75rem;
			width: 100px;
	}
	.filter-button-link {
			text-align: left;
			padding-left: 0;
	}
}
// <--

// -->css for full grid
.full-grid {
	background-color: #fff;
	&.card {
		margin-bottom: 0;
	}

	.table th, .table td {
		padding:0.4rem;
	}
	
	.grid-pagination {
			float: right;
	}
	.below {
			margin: 0.5rem 0 0 0;
	}
	.grid-filter {
			float: left;
	}
	.grid-table {
		margin: 0.5rem 0 0.5rem 0;
		.grid-table-options {
			width: 110px;
		}
	}
}
// <--

