body {
  font-size: 0.75rem;
}

.form-control {
  font-size: 0.75rem;
  padding: 0.25rem;
  min-height: 32px !important;
}

.btn {
  font-size: 0.815rem;
  padding: 0.25rem;
  font-weight: 500;
}

.ag-icon-contracted,
.ag-icon-expanded {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.icon-briefcase {
  color: lightgreen !important;
}

.link-router-black {
  color: black;
}

.card-body {
  padding: 1rem 0 1rem 0;
}

.page-link,
#pageNumberInput .page-input {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  height: 32px !important;
}

.card-header {
  padding: 1.25rem 0.5rem 1.25rem 0.5rem;
}

.table th,
.table td {
  font-size: 0.75rem;
  padding: 0.25rem;
  vertical-align: middle;
}

.pagination {
  justify-content: flex-end;
}

button {
  border-radius: 2px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 50px !important;
  padding: 5px 10px !important;
}

.custom-tooltip {
  position: absolute;
  width: 250px;
  height: 150px;
  overflow: hidden;
  z-index: 500;
  pointer-events: none;
  background-color: #242424;
  transition: opacity 0.5s;
  padding: 10px;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.avatar-menu {
  right: auto;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-132px, 35px, 0px);
}

.form-control {
  height: 32px;
  border-radius: 0;
  border-color: #9c9a9a;
}

.abscense-report .css-bg1rzq-control {
  // height: auto;
}

.css-16pqwjk-indicatorContainer,
.css-1thkkgx-indicatorContainer {
  padding: 4px !important;
}
.css-bg1rzq-control,
.css-14ojxsb-control,
.css-1szy77t-control {
  min-height: 32px !important;
  border-color: #9c9a9a !important;
  border-radius: 0 !important;
}

.css-1hwfws3 {
  align-self: flex-start;
  padding: 2px 4px !important;
}

.schema-tr {
  display: inline-flex !important;
  flex-direction: column;
  width: 200px;
}

.schema-tr:hover::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.075);
  left: 0;
}

.table-schema-header {
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
}

.table-header-label {
  font-size: 1.2rem !important;
  font-weight: 500;
}

.header-wrapper {
  padding-left: 1.25rem;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-right: 1.25rem;
}

.form-group {
  margin-bottom: 0.25rem;
}

label {
  margin-bottom: 0.05rem;
}

.btn-link {
  font-size: 0.75rem;
  padding: 0;
  text-align: left;
  margin: auto;
  font-weight: 500;
  text-decoration: underline;
}

.grid-table {
  border-spacing: 5rem;
  border-collapse: collapse;
}

.link-router-black:hover {
  color: black;
}

.search-date {
  font-weight: bold;
  margin-left: 0;
}
.user-detail {
  width: 100%;
  .user-detail-label {
    font-size: 1.4rem;
  }
  .user-detail-btn {
    float: right;
  }
}
// ipWhitelist
.ipWhitelist {
  .ipWhitelist-alert {
    display: inline;
    margin-left: 10px;
  }
}
// user-entitlements-options
.user-entitlements-options {
  .btn-cancel {
    margin-right: 10px;
  }
}

.advance-area {
  margin: 0.5rem 0 0.5rem 0;
  .input-product-tag {
    width: 300px;
  }
  .select-brand {
    width: 300px;
    margin: 0.75rem 0 0 0;
    .css-bg1rzq-control {
      border-color: hsl(0, 5%, 92%);
    }
  }
  .date {
    width: 300px;
    margin: 0.5rem 0 0.5rem 0;
    height: 33px;
    .date-input {
      width: 145px;
    }
    .left {
      float: left;
    }
    .right {
      float: right;
    }
  }
  .date-label {
    width: 300px;
    height: 19px;
    margin: 0.5rem 0 0 0;
    font-weight: bold;
    .left {
      width: 155px;
      float: left;
    }
  }
}

.ok {
  background: transparent url(../src/assets/img/ok.png) no-repeat left center;
  background-size: 14px 14px;
  padding-left: 20px;
}

.dice {
  background: transparent url(../src/assets/img/dice.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 20px;
}

.not-ok {
  background: transparent url(../src/assets/img/not-ok.png) no-repeat left center;
  background-size: 14px 14px;
  padding-left: 20px;
}

.cross-not-ok {
  background: transparent url(../src/assets/img/cross.png) no-repeat left center;
  background-size: 12px 12px;
  padding-left: 20px;
}

.edit {
  background: transparent url(../src/assets/img/pen.png) no-repeat left center;
  background-size: 20px 20px;
  padding-left: 20px;
}

.remove {
  background: transparent url(../src/assets/img/remove.png) no-repeat left center;
  background-size: 16px 16px;
  padding-left: 20px;
}
.check {
  background: transparent url(../src/assets/img/checkmar.png) no-repeat left center;
  background-size: 14px 14px;
  padding-left: 20px;
}
.star {
  background: transparent url(../src/assets/img/start.png) no-repeat left center;
  background-size: 12px 12px;
  padding-left: 20px;
}

.down {
  background: transparent url(../src/assets/img/down.png) no-repeat left center;
  background-size: 8px 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.right {
  background: transparent url(../src/assets/img/right.png) no-repeat left center;
  background-size: 8px 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.del {
  background: transparent url(../src/assets/img/del-btn.PNG) no-repeat left center;
  background-size: 18px 18px;
  padding-left: 12px;
  padding-right: 8px;
}

.warning {
  color: #ce2700;
  background: transparent url(../src/assets/img/warning.png) no-repeat left center;
  font-size: 14px;
  padding-left: 20px;
}

.btn-create {
  float: right;
}
.title-small {
  font-size: 1rem;
  font-weight: bold;
}
.title-medium {
  font-size: 1.2rem;
  font-weight: bold;
}
.card-noborder {
  border: none;
}
.card-header-empty {
  border: none;
  background-color: #ffffff;
}

.schema-col:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/* Authentication */
.authentication-wrapper {
  height: auto !important;
  padding-top: 2rem;
}

.authentication-container {
  max-width: 80rem;
}

.guide {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid #ccc;
      margin: 20px 0;
    }

    &-content {
    }

    &-desc {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__step {
    font-size: 14px;
    padding: 0 10px;
    margin-bottom: 20px;

    // Arrow bottom right
    &-arrow--right {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid;
      transform: scale(var(--ggs, 6));
      border-right: 2px solid;
      border-color: red;
      margin: 0 auto;
      bottom: -40px;
      z-index: 100;
    }
    &-arrow--right::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 14px;
      height: 2px;
      background: red;
      transform: rotate(45deg);
      bottom: 3px;
      right: -3px;
    }
    // End arrow bottom right

    // Arrow down
    &-arrow--down {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 5));
      width: 22px;
      height: 22px;
      margin: 0 auto;
      bottom: -60px;
      z-index: 100;
    }
    &-arrow--down::after,
    &-arrow--down::before {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      bottom: 4px;
    }
    &-arrow--down::after {
      width: 8px;
      height: 8px;
      border-bottom: 2px solid;
      border-left: 2px solid;
      transform: rotate(-45deg);
      left: 7px;
      border-color: red;
    }
    &-arrow--down::before {
      width: 2px;
      height: 16px;
      left: 10px;
      background: red;
    }
    // End arrow down

    &-img {
      // width: 100%;

      > img {
        width: 100%;
      }
    }
  }

  &__footer {
  }
}
// Switch button
/* Rounded sliders */
.switch {
  display: flex;
  align-items: center;
  width: 126px;

  &__button {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &--round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }

  .switch__label {
    font-size: 16px;
    flex: 1;
    display: inline-block;
    text-align: center;
  }
}

.switch__slider.switch__slider--active {
  background-color: #2196f3;
  box-shadow: 0 0 1px #2196f3;
  &:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
// End switch button
/* End Authentication */

.table-data {
  overflow: scroll;
  height: calc(100vh - 210px);
}

@media (min-width: 320px) {
  .guide {
    &__body {
    }
    &__step {
      width: 100%;
      &-arrow--right {
        display: none;
      }
      &-arrow--down {
        display: block;
        bottom: -30px;
      }
    }
  }
}

@media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
  }
  .guide {
    &__step {
      width: 50%;
      &-arrow--down {
        display: none;
      }
      &-arrow--right {
        display: block;
      }
    }
  }
}
@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
  .schema-danceschool {
    padding-left: 50px !important;
  }
  .guide {
    &__step {
      width: 33.33%;
      &-arrow--right {
        display: none;
      }
      &-arrow--down {
        display: block;
        bottom: -32px;
      }
    }
  }
  .guide__step-arrow--down.guide__step-ios {
    bottom: -200px;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: 14.285714285714285714285714285714% !important;
    flex: none !important;
  }
  .guide {
    &__step {
      &-arrow--down {
        bottom: -60px;
      }
    }
  }
}

//product detail
.no-mrg-bottom {
  margin-bottom: 0;
}
.mrg-left {
  margin-left: 2rem;
}
.mrg-top {
  margin-top: 1rem;
}
.alert-product-detail {
  margin: 1rem 2rem 0 2rem;
}

.course-select {
  min-width: 250px;
}

.reduced-padding-grid {
  padding-left: 0;
}

.reduced-padding-tabcontent .tab-pane {
  padding: 0.5rem;
}

.edit-user-add-course-btn {
  float: right;
  margin-right: 14px;
  width: 77px;
}

.text-bold {
  margin-left: 5px;
  font-weight: 600;
  font-style: italic;
}

.family-inline-group.css-1pcexqc-container {
  display: inline-block;
  width: 320px;
  margin: 0 10px;
}
.family-inline-group.form-group {
  float: right;
}
.clear-both {
  clear: both;
}
.link-adv {
  margin-top: 5px;
}
.label-align {
  margin-top: 8px;
  margin-right: 15px;
}
.head-col {
  white-space: nowrap;
}
.ag-header .ag-pinned-left-header .ag-header-cell-label {
  justify-content: left;
}
.abscense-report .ag-header .ag-header-cell-label {
  justify-content: center;
}
.text-min-height {
  min-height: 87px !important;
}

.schema-danceschool {
  background: #fff;
  padding-left: 0;
  left: 0;
  right: 0;
}
.schema-danceschool .grid-header {
  margin: -15px;
}
.schema-danceschool .grid-header .title {
  font-weight: bold;
  font-size: 18px;
}
.schema-danceschool .react-datepicker-wrapper,
.schema-danceschool .grid-pagination {
  display: inline-block !important;
}
.schema-danceschool .card-item {
  background: #7bb9ff;
  border-radius: 2px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.schema-danceschool .card-item:hover {
  box-shadow: 3px 3px #eee;
  border-bottom-color: #9c9a9a;
  border-right-color: #9c9a9a;
}

.dashboard .toast {
  box-shadow: none;
  max-width: 100% !important;
  margin: 5px 0 15px 0;
}

.dashboard .price-freely {
  height: 35px;
  max-width: 150px;
}
.dashboard .form-check-input {
  position: initial;
  margin-top: 0;
  margin-left: 0;
}

.email-module .label-form {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

textarea.minHeight {
  min-height: 196px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.floatingActions {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}

.schema-table {
  overflow: scroll;
  height: calc(100vh - 214px);
  table {
    border-collapse: collapse;
  }
}
.schema-table thead th {
  position: sticky;
  top: 0;
  transform: translateY(-2px);
  background-color: #fff;
  padding: 0 !important;
}

.schema-header-fix-border {
  border-top: 1px solid #2f353a;
}

@media (min-width: 910px) {
  .schema-table {
    height: calc(100vh - 180px);
  }
}

.submitted-error {
  .custom-control-label::before {
    border-color: #ce2700;
    content: "x";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #b1381c;
    font-weight: 700;
  }
}


.year-and-semester-group{
  width: 140px;
  display: inline-block;

  .filter-year-selector{
    width: 84px;
    display: inline-block;
  }
  .filter-semester-selector{
    width: 66px;
    display: inline-block;
  }
}