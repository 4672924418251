@keyframes loader-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.fixed-loader-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3); 
}

.main-loader {
  width: 56px;
  height: 56px;
  border: 8px solid transparent;
  border-top-color: #20a8d8;
  border-bottom-color: #20a8d8;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1.2s linear infinite;
  top: 50%;
  margin: -28px auto 0; 
}
